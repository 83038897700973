/* eslint-disable no-unused-vars */
import { Web3GameService } from '../../services/Web3GameService';
export default {
  name: 'AirDropMatic',
  props: {
    msg: String,
  },
  data: () => ({
    game: null,
    nftID: '',
    nftAddress: '',
    walletToName: {
      '0xFF43eD599B3c80fB71bb67c9D5452765e63d7a43': 'Bruce',
      '0xad7595fb1b3dF0468522106312F9c968152059A7': 'Nicholas',
      '0xf5C9d16C74269DcD8281C91490fC69564372a55F': 'Jonathan',
      '0x3D940e2Ee4430C1fF1c8D345A70ba82ED3823E48': 'Eduardo',
    },
    web3Config: new Web3GameService(),
    account: null,
    username: ''
  }),
  methods: {
    getWeb3Config() {
      this.web3Config
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          console.log(this.account)
          this.username = this.walletToName[this.account];
          this.accountsWatch();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    accountsWatch() {
      if (this.web3Config.provider) {
        this.web3Config.provider.on('accountsChanged', (accounts) => {
          this.account = accounts[0];
          this.web3Config.account = accounts[0];
        });
      }
    },
    async airdropMatic() {
      await this.web3Config.airdropMatic();
    },
    async setMaticBeingSent(maticAirDropValue) {
      console.log("Coco: ", maticAirDropValue)
      await this.web3Config.setMaticBeingSent(maticAirDropValue);
    },
    async updateOwners(nftContractAddresses, nftIDs, ownerAddresses) {
      nftContractAddresses = nftContractAddresses.split(',');
      nftIDs = nftIDs.split(',');
      ownerAddresses = ownerAddresses.split(',');
      this.web3Config.updateOwnersAirDrop(nftContractAddresses, nftIDs, ownerAddresses);
    }
  },
  mounted() {
    this.getWeb3Config();
  },
};
