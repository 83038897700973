<template>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">Value</label>
            <input type="text" v-model="maticAirDropValue">
        </div>
        <button style="margin: 10px" @click="setMaticBeingSent(maticAirDropValue)">
            Set Matic airdrop
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> This is the amount nft owners are gonna receive for playing the house game.</p>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px;">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">NFT Addresses </label>
            <input type="text" v-model="nftContractAddressUpdateBatch">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">NFT IDS </label>
            <input type="text" v-model="nftIDUpdateBatch">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Owner Addresses </label>
            <input type="text" v-model="ownerAddressUpdateBatch">
        </div>
        <button style="margin: 10px" @click="updateOwners(nftContractAddressUpdateBatch, nftIDUpdateBatch,ownerAddressUpdateBatch)">
            Update NFT Owner Batch
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <button style="margin: 10px" @click="airdropMatic()">
            Airdrop matic
        </button>
        <p style="border: 5px solid blue; padding: 5px;"> <strong style="color: red; font-weight: 900;">Explanation: </strong> This is the amount nft owners are gonna receive for playing the house game.</p>
    </div>
    
</template>

<script src="./AirDropMatic.js"></script>
